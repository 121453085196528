.br-theme-bars-horizontal .br-widget {
  width: 120px;
  white-space: nowrap;
}
.br-theme-bars-horizontal .br-widget a {
  display: block;
  width: 120px;
  height: 5px;
  background-color: #fbedd9;
  margin: 1px;
}
.br-theme-bars-horizontal .br-widget a.br-active,
.br-theme-bars-horizontal .br-widget a.br-selected {
  background-color: #EDB867;
}
.br-theme-bars-horizontal .br-widget .br-current-rating {
  width: 120px;
  font-size: 18px;
  font-weight: 600;
  line-height: 2;
  text-align: center;
  color: #EDB867;
}
.br-theme-bars-horizontal .br-readonly a {
  cursor: default;
}
.br-theme-bars-horizontal .br-readonly a.br-active,
.br-theme-bars-horizontal .br-readonly a.br-selected {
  background-color: #f2cd95;
}
.br-theme-bars-horizontal .br-readonly .br-current-rating {
  color: #f2cd95;
}
@media print {
  .br-theme-bars-horizontal .br-widget a {
    border: 1px solid #b3b3b3;
    background: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .br-theme-bars-horizontal .br-widget a.br-active,
  .br-theme-bars-horizontal .br-widget a.br-selected {
    border: 1px solid black;
    background: white;
  }
  .br-theme-bars-horizontal .br-widget .br-current-rating {
    color: black;
  }
}
