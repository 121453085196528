@use '@angular/material' as mat;

@mixin spinner-container-theme($theme) {

	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$warn: map-get($theme, warn);

	.spinner-background {
		.spinner-container.mat-primary & {
			color: mat.m2-get-color-from-palette($primary);
		}
		.spinner-container.mat-accent & {
			color: mat.m2-get-color-from-palette($accent);
		}
		.spinner-container.mat-warn & {
			color: mat.m2-get-color-from-palette($warn);
		}
	}
}
