.br-theme-bars-reversed .br-widget {
  height: 25px;
  white-space: nowrap;
}
.br-theme-bars-reversed .br-widget a {
  display: block;
  width: 22px;
  height: 22px;
  float: left;
  background-color: #bef5e8;
  margin: 1px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  color: #50E3C2;
  text-align: center;
}
.br-theme-bars-reversed .br-widget a.br-active,
.br-theme-bars-reversed .br-widget a.br-selected {
  background-color: #50E3C2;
  color: white;
}
.br-theme-bars-reversed .br-widget .br-current-rating {
  line-height: 1.3;
  float: left;
  padding: 0 20px 0 20px;
  color: #50E3C2;
  font-size: 17px;
  font-weight: 400;
}
.br-theme-bars-reversed .br-readonly a {
  cursor: default;
}
.br-theme-bars-reversed .br-readonly a.br-active,
.br-theme-bars-reversed .br-readonly a.br-selected {
  background-color: #7cead1;
}
.br-theme-bars-reversed .br-readonly .br-current-rating {
  color: #7cead1;
}
@media print {
  .br-theme-bars-reversed .br-widget a {
    border: 1px solid #b3b3b3;
    background: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .br-theme-bars-reversed .br-widget a.br-active,
  .br-theme-bars-reversed .br-widget a.br-selected {
    border: 1px solid black;
    background: white;
  }
  .br-theme-bars-reversed .br-widget .br-current-rating {
    color: black;
  }
}
