.br-theme-bars-square .br-widget {
  height: 15px;
  white-space: nowrap;
}
.br-theme-bars-square .br-widget a {
  display: block;
  width: 30px;
  height: 30px;
  float: left;
  border: 2px solid #bbcefb;
  background-color: white;
  margin: 2px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 2;
  text-align: center;
  color: #bbcefb;
  font-weight: 600;
}
.br-theme-bars-square .br-widget a.br-active,
.br-theme-bars-square .br-widget a.br-selected {
  border: 2px solid #4278F5;
  color: #4278F5;
}
.br-theme-bars-square .br-widget .br-current-rating {
  clear: both;
  width: 330px;
  text-align: center;
  font-weight: 600;
  display: block;
  padding: .5em 0;
  color: #646464;
}
.br-theme-bars-square .br-readonly a {
  cursor: default;
}
.br-theme-bars-square .br-readonly a.br-active,
.br-theme-bars-square .br-readonly a.br-selected {
  border: 2px solid #729bf8;
  color: #729bf8;
}
@media print {
  .br-theme-bars-square .br-widget a {
    border: 2px solid #b3b3b3;
    color: #b3b3b3;
  }
  .br-theme-bars-square .br-widget a.br-active,
  .br-theme-bars-square .br-widget a.br-selected {
    border: 2px solid black;
    color: black;
  }
}
